<template>
    <div>

        <ConfirmationDialog v-bind="confirmationDialogParameters"
                            v-on:delete-bend-allowance-deduction="deleteBendAllowanceDeduction"/>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:ok="setData"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">
                <div class="container p-0">
                    <div class="row justify-content-center">
                        <div class="col-xl-10 p-0 mb-1">

                            <!-- PARAMETERS -->

                            <div class="card st-fadein-content mt-4">
                                <div class="card-body">
                                    <table class="mb-2" style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.t('translations.Parameters') }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.parameters">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:update-parameters="updateParameters"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="ml-3">
                                        <tbody>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.t('translations.Application') }}</td>
                                            <td>{{ data.parameters.application }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.t('translations.Scanner App Version') }}</td>
                                            <td>{{ data.parameters.scanner_app_version }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.t('translations.SketchUp Plugin Version') }}</td>
                                            <td>{{ data.parameters.sketchup_plugin_version }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.t('translations.Punch Plates Utility Version') }}</td>
                                            <td>{{ data.parameters.punch_plates_utility_version }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- SCHEDULER -->

                            <div class="card st-fadein-content mt-4">
                                <div class="card-body">
                                    <table class="mb-2" style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.t('translations.Scheduler') }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.scheduler">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:update-scheduler="updateScheduler"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="ml-3">
                                        <tbody>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.t('translations.Data Export') }}</td>
                                            <td>
                                                <template v-if="data.scheduler.export === 1">
                                                    {{ (data.export && data.export.last_run) ? data.export.last_run : 'active' }}
                                                </template>
                                                <template v-else>
                                                    disabled
                                                </template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.tc('translations.Reminder', 2) }}</td>
                                            <td>
                                                <template v-if="data.scheduler.reminders === 1">
                                                    {{ (data.reminders && data.reminders.last_run) ? data.reminders.last_run : 'active' }}
                                                </template>
                                                <template v-else>
                                                    disabled
                                                </template>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- ESTIMATE DEFAULTS -->

                            <div class="card st-fadein-content mt-4">
                                <div class="card-body">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.tc('translations.Defaults', 1) }} {{ $i18n.tc('translations.Estimate', 1) }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.defaults">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:update-defaults="updateEstimate"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <!-- QUOTE DEFAULTS -->

                            <div class="card st-fadein-content mt-4">
                                <div class="card-body">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.tc('translations.Defaults', 1) }} {{ $i18n.tc('translations.Quote', 1) }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.defaults">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:update-defaults="updateQuote"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <!-- ORDER DEFAULTS -->

                            <div class="card st-fadein-content">
                                <div class="card-body">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.tc('translations.Defaults', 1) }} {{ $i18n.tc('translations.Order', 1) }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.defaults">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:update-defaults="updateOrder"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <!-- SKETCHUP ASSEMBLAGE -->

                            <div class="card st-fadein-content mt-4">
                                <div class="card-body">
                                    <table class="mb-2" style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.tc('translations.SketchUp', 1) }} {{ $i18n.tc('translations.Assembly', 1) }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.sketchup_assembly">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:update-sketchup="updateSketchUp"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="ml-3" v-if="data.sketchup">
                                        <tbody>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.tc('translations.Dimensions Tag', 1) }}</td>
                                            <td>{{ data.sketchup.assembly_tag }}</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 280px;">{{ $i18n.tc('translations.Definition', 2) }}</td>
                                            <td>{{ data.sketchup.assembly_definitions_string }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- SKETCHUP BEND ALLOWANCE/DEDUCTION -->

                            <div class="card st-fadein-content mt-4">
                                <div class="card-body">
                                    <table class="mb-2" style="width: 100%;">
                                        <tr>
                                            <td><h5>{{ $i18n.tc('translations.SketchUp', 1) }} {{ $i18n.tc('translations.Bend Allowance/Deduction', 1) }}</h5></td>
                                            <td style="text-align: right;">
                                                <template v-for="(action,actionIndex) in actions.sketchup_bend_allowance_deduction">
                                                    <template v-if="action.placement === 'ELEMENTHEADER'">
                                                        <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                                      v-on:add-bend-allowance-deduction="addBendAllowanceDeduction"/>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                    <Table card-class="m-0 mb-2" card-style="border:none;" body-class="p-0" no-actions-label
                                           :reload-table="reloadTable" th-actions-style="width: 80px;"
                                           :fields="fields.sketchup_bend_allowance_deduction" :actions="actions.sketchup_bend_allowance_deduction"
                                           :rows="data.sketchup.bend_allowance_deduction"
                                           v-on:delete-bend-allowance-deduction="confirmDeleteBendAllowanceDeduction"
                                           v-on:edit-bend-allowance-deduction="editBendAllowanceDeduction"
                                           v-if="data.sketchup"/>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import RecordDialog from "@/components/dialogs/RecordDialog";
import Table from "@/components/Table.vue";

export default {
    name: 'Settings',
    components: {
        ButtonAction,
        ConfirmationDialog,
        RecordDialog,
        Table,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            data: null,
            reloadTable: 0,
            confirmationDialogParameters: {
                type: '',
                title: '',
                message: '',
                ok_event: 'ok',
                ok_text: 'OK',
                cancel_event: 'cancel',
                cancel_text: this.$i18n.t("translations.Cancel"),
                data: null,
            },
            showRecordDialog: false,
            recordDialogParameters: {
                size: null,
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        addBendAllowanceDeduction() {
            this.recordDialogParameters.size = 'md';
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Bend Allowance/Deduction", 1);
            this.recordDialogParameters.api = this.api + '/add_bend_allowance_deduction';
            this.recordDialogParameters.fields = this.fields.sketchup_bend_allowance_deduction;
            this.recordDialogParameters.data = null;
            this.showRecordDialog = true;
        },
        confirmDeleteBendAllowanceDeduction(data) {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.tc("translations.Delete Bend Allowance/Deduction", 1);
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to delete this bend allowance/deduction?");
            this.confirmationDialogParameters.data = data;
            this.confirmationDialogParameters.ok_event = 'delete-bend-allowance-deduction';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Delete");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.$bvModal.show('confirmation-dialog');
        },
        deleteBendAllowanceDeduction(data) {
            this.state.loading = true;

            let idx = this.data.sketchup.bend_allowance_deduction.findIndex(row =>
                (row.thickness_from === data.thickness_from) &&
                (row.thickness_to === data.thickness_to) &&
                (row.angle_from === data.angle_from) &&
                (row.angle_to === data.angle_to) &&
                (row.bend_allowance_deduction === data.bend_allowance_deduction)
            );
            this.data.sketchup.bend_allowance_deduction.splice(idx, 1);

            this.$http.put(this.api + '/update_sketchup', {
                bend_allowance_deduction: this.data.sketchup.bend_allowance_deduction,
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Settings:deleteBendAllowanceDeduction():error:", error);
            });
        },
        editBendAllowanceDeduction(data) {
            let idx = this.data.sketchup.bend_allowance_deduction.findIndex(row =>
                (row.thickness_from === data.thickness_from) &&
                (row.thickness_to === data.thickness_to) &&
                (row.angle_from === data.angle_from) &&
                (row.angle_to === data.angle_to) &&
                (row.bend_allowance_deduction === data.bend_allowance_deduction)
            );
            this.recordDialogParameters.size = 'md';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Bend Allowance/Deduction", 1);
            this.recordDialogParameters.api = this.api + '/update_bend_allowance_deduction/' + idx;
            this.recordDialogParameters.fields = this.fields.sketchup_bend_allowance_deduction;
            this.recordDialogParameters.data = data;
            this.showRecordDialog = true;
        },
        fetchData() {
            this.state.loading = true;
            this.$http.get(this.api + '/manage', {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
                this.pageLoaded = true;
            }).catch((error) => {
                console.log("Settings:fetchData():error:", error);
            });
        },
        setData(data) {
            this.actions = data.actions;
            this.fields = data.fields;
            this.data = data.data;
            this.reloadTable++;
        },
        updateEstimate() {
            this.recordDialogParameters.size = 'xl';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Estimate", 1);
            this.recordDialogParameters.api = this.api + '/update_defaults';
            this.recordDialogParameters.fields = this.fields.estimate;
            this.recordDialogParameters.data = this.data.defaults;
            this.showRecordDialog = true;
        },
        updateOrder() {
            this.recordDialogParameters.size = 'xl';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Order", 1);
            this.recordDialogParameters.api = this.api + '/update_defaults';
            this.recordDialogParameters.fields = this.fields.order;
            this.recordDialogParameters.data = this.data.defaults;
            this.showRecordDialog = true;
        },
        updateParameters() {
            this.recordDialogParameters.size = 'xl';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.Parameters");
            this.recordDialogParameters.api = this.api + '/update_parameters';
            this.recordDialogParameters.fields = this.fields.parameters;
            this.recordDialogParameters.data = this.data.parameters;
            this.showRecordDialog = true;
        },
        updateQuote() {
            this.recordDialogParameters.size = 'xl';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Quote", 1);
            this.recordDialogParameters.api = this.api + '/update_defaults';
            this.recordDialogParameters.fields = this.fields.quote;
            this.recordDialogParameters.data = this.data.defaults;
            this.showRecordDialog = true;
        },
        updateScheduler() {
            this.recordDialogParameters.size = 'xl';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.Scheduler");
            this.recordDialogParameters.api = this.api + '/update_scheduler';
            this.recordDialogParameters.fields = this.fields.scheduler;
            this.recordDialogParameters.data = this.data.scheduler;
            this.showRecordDialog = true;
        },
        updateSketchUp() {
            this.recordDialogParameters.size = 'xl';
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.SketchUp");
            this.recordDialogParameters.api = this.api + '/update_sketchup';
            this.recordDialogParameters.fields = this.fields.sketchup_assembly;
            this.recordDialogParameters.data = this.data.sketchup;
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
