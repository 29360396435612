<template>
    <b-modal id="component-dialog" :size="size"
             no-close-on-backdrop
             v-on:hidden="$emit('hidden')">

        <template #modal-title>
            <font-awesome-icon :icon="icon" size="sm" class="mr-2" v-if="icon"/>
            {{ title }}
        </template>

        <div class="m-n3">
            <Table class="m-0" header-class="light" footer-class="light" body-class=""
                   :actions="actions" :fields="fields" :rows="rows"
                   with-pagination forward-events
                   v-on:forward-event="handleForwardedEvent"/>
        </div>

        <template #modal-footer="{ ok }">
            <b-button pill variant="dark" class="pl-5 pr-5" v-on:click="ok()">
                {{ $i18n.t('translations.Close') }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    name: "ComponentDialog",
    components: {
        Table,
    },
    props: {
        size: {type: String, default: 'lg'},
        icon: {type: Array, default: () => ['fas', 'list-ul']},
        title: {type: String, default: ''},
        actions: {type: Array, default: () => []},
        fields: {type: Array, default: () => []},
        rows: {type: Array, default: () => []},
    },
    mounted() {
        this.$bvModal.show('component-dialog');
    },
    methods: {
        handleForwardedEvent(eventData) {
            this.$emit(eventData.event, eventData.data);
        },
    }
}
</script>

<style scoped>
</style>
