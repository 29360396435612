<template>
    <div>

        <ActualizeRulesDialog v-if="showActualizeRulesDialog"
                              v-bind="actualizeRulesDialogParameters"
                              v-on:hidden="showActualizeRulesDialog = false"
                              v-on:ok="setData"/>

        <ConfirmationDialog v-bind="confirmationDialogParameters"
                            v-on:delete-selected-rules="deleteSelectedRules"
                            v-on:delete-selected-section-rules="deleteSelectedSectionRules"/>

        <RecordDialog v-if="showRecordDialog"
                      v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:ok="fetchData(data.id)"
                      v-on:success-create-document="successCreateDocument"
                      v-on:success-set-data="setData"/>

        <PrintDocumentOptionsDialog v-if="showPrintDocumentOptionsDialog"
                                    v-bind="printDocumentOptionsDialogParameters"
                                    v-on:hidden="showPrintDocumentOptionsDialog = false"
                                    v-on:ok="printDocument"/>

        <!-- DOCUMENT HEADER -->

        <div class="card st-framework-card" v-if="pageLoaded">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <template v-if="type === 'order' || type === 'managed_order'">
                            <td style="margin: 0; padding: 0 0 0 1rem; width: 280px; border: solid 1px lightgray;">
                                <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                                <template v-for="(action,index) in actions.manageActions">
                                    <SelectAction :key="action.name + '-' + index"
                                                  :action="action" :disabled="state.disabled"
                                                  v-on:select-view="selectView"
                                                  v-if="action.type === 'SELECT' && action.placement === 'DOCUMENTHEADERLEFT'"/>
                                </template>
                            </td>
                        </template>
                        <template v-else>
                            <td style="margin: 0; padding: 0 0 0 1rem; width: 280px;">
                                <font-awesome-icon class="mr-2" :icon="icon" size="sm"/>
                                {{ $i18n.tc('translations.' + document, 1).toUpperCase() }}
                            </td>
                        </template>
                        <td style="width: 1rem;"></td>
                        <td style="margin: 0; padding: 0 10px; border: solid 1px lightgray;">
                            <SelectField :field="fields.selectField" v-on:input="documentSelectInput"/>
                        </td>
                        <td style="margin: 0; padding: 0; border: solid 1px lightgray; text-align: center; width: 38px;"
                            v-if="showCreateButton">
                            <template v-for="(action,index) in actions.manageActions">
                                <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                              v-on:create-document="createDocument"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </td>
                        <td style="margin: 0; padding: 0; width: 90px; text-align: right;">(#{{ data.id }})</td>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body st-fadein-content p-0">
                <div class="row no-gutters">

                    <!-- DOCUMENT CARD -->

                    <div class="col-4">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.' + document, 1) }} ({{ data.status_text }})
                                    <template v-for="(action,index) in actions.manageActions">
                                        <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                      v-on:update-document="updateDocument"
                                                      v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTCARDHEADER'"/>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless" style="margin-bottom: 0">
                                    <tbody>
                                    <tr>
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Number') }}</b></td>
                                        <td class="p-0">{{ data.financial_year_number }}</td>
                                    </tr>
                                    <tr v-if="data.reference">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Reference') }}</b></td>
                                        <td class="p-0">{{ data.reference }}</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.name }}</td>
                                    </tr>
                                    <tr v-if="data.date">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Date') }}</b></td>
                                        <td class="p-0">{{ data.date }}</td>
                                    </tr>
                                    <tr v-else>
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Valid') }}</b></td>
                                        <td class="p-0">{{ data.valid_from + ' ' + $i18n.t('translations.until') + ' ' + data.valid_to }}</td>
                                    </tr>
                                    <tr v-if="data.delivery_date">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Delivery Date') }}</b></td>
                                        <td class="p-0">{{ data.delivery_date }}</td>
                                    </tr>
                                    <tr v-if="data.invoice_date">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Invoice Date') }}</b></td>
                                        <td class="p-0">{{ data.invoice_date }}</td>
                                    </tr>
                                    <tr v-if="data.invoice_number">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Invoice Number') }}</b></td>
                                        <td class="p-0">{{ data.invoice_number }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-body light" style="border: none; padding: 0 .5rem;"  v-if="actions.previousVersion || actions.nextVersion">
                                <div class="btn-toolbar" role="toolbar" style="">
                                    <div class="btn-group btn-group-sm" role="group" v-if="actions.previousVersion">
                                        <RouteAction :key="actions.previousVersion.name" :action="actions.previousVersion" :data="data"/>
                                    </div>
                                    <div class="btn-group btn-group-sm ml-auto" role="group" v-if="actions.nextVersion">
                                        <RouteAction :key="actions.nextVersion.name" :action="actions.nextVersion" :data="data"/>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer light" v-if="showDocumentCardFooter">
                                <div class="row">
                                    <div class="col">
                                    </div>
                                    <div class="col-auto">
                                        <template v-for="(action,index) in actions.manageActions">
                                            <template v-if="action.placement === 'DOCUMENTCARDFOOTERRIGHT'">
                                                <DropDownAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                                v-on:copy-as="copyAs"
                                                                v-if="action.type === 'DROPDOWN'"/>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- PROJECT CARD -->

                    <div class="col-4">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Project', 1) }} ({{ data.project_id.status_text }})
                                    <template v-if="!state.readonly">
                                        <template v-for="(action,index) in actions.manageActions">
                                            <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                          v-on:update-project="updateProject"
                                                          v-if="action.type === 'BUTTON' && action.placement === 'PROJECTCARDHEADER'"/>
                                        </template>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless" style="margin-bottom: 0">
                                    <tbody>
                                    <tr>
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Number') }}</b></td>
                                        <td class="p-0">{{ data.project_id.id }}</td>
                                    </tr>
                                    <template v-for="(action,index) in actions.manageActions">
                                        <template v-if="action.placement === 'PROJECTCARDBODY' && action.type === 'LINK'">
                                            <tr :key="'project-' + action.name + '-' + index">
                                                <td class="p-0" style="width: 135px;"><b>{{ action.label }}</b></td>
                                                <td class="p-0">
                                                    <LinkAction :action="action"></LinkAction>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <tr v-if="data.project_id.reference">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Reference') }}</b></td>
                                        <td class="p-0">{{ data.project_id.reference }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.manager">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Manager') }}</b></td>
                                        <td class="p-0">{{ data.project_id.manager }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.correspondent">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Correspondent') }}</b></td>
                                        <td class="p-0">{{ data.project_id.correspondent }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- CUSTOMER CARD -->

                    <div class="col-4">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Customer', 1) }}
                                    <template v-if="!state.readonly">
                                        <template v-for="(action,index) in actions.manageActions">
                                            <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                          v-on:update-customer="updateCustomer"
                                                          v-if="action.type === 'BUTTON' && action.placement === 'CUSTOMERCARDHEADER'"/>
                                        </template>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless" style="margin-bottom: 0">
                                    <tbody>
                                    <tr v-if="data.project_id.customer_id.full_name">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.full_name }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.company">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.Company') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.company }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.street || data.project_id.customer_id.city || data.project_id.customer_id.country">
                                        <td class="p-0" style="width: 135px; vertical-align: top;"><b>{{ $i18n.t('translations.Address') }}</b></td>
                                        <td class="p-0">
                                            <span v-if="data.project_id.customer_id.street">{{ data.project_id.customer_id.street }} {{ data.project_id.customer_id.number }}<br></span>
                                            <span v-if="data.project_id.customer_id.city">{{ data.project_id.customer_id.postal }} {{ data.project_id.customer_id.city }}<br></span>
                                            <span v-if="data.project_id.customer_id.country">{{ data.project_id.customer_id.country }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.vat">
                                        <td class="p-0" style="width: 135px;"><b>{{ $i18n.t('translations.VAT') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.vat }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- DOCUMENT SECTIONS HEADER -->

                <div class="sections-header-sticky" v-if="!state.readonly && showSectionsHeader">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col">
                                    <template v-if="type === 'managed_order'">
                                        {{ $i18n.tc('translations.Phase', 2) }}
                                    </template>
                                    <template v-else>
                                        {{ $i18n.tc('translations.Category', 2) }}
                                    </template>
                                </div>
                                <div class="col-auto">
                                    <template v-for="(action,index) in actions.manageActions">
                                        <ButtonAction :key="action.name + '-' + index" :action="action"
                                                      v-on:add-section="addSection"
                                                      v-if="action.placement === 'GROUPHEADERRIGHT' && action.type === 'BUTTON'"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <b-form inline>
                                <template v-for="(action,actionIndex) in actions.manageActions">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action" :disabled="!rowsSelected"
                                                  v-on:set-quantity="setQuantityForSelectedRules"
                                                  v-if="action.placement === 'GROUPBODY' && action.type === 'BUTTON' && action.name === 'set-quantity'"/>
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action" :disabled="!rowsSelected"
                                                  v-on:set-markup="setMarkupForSelectedRules"
                                                  v-if="action.placement === 'GROUPBODY' && action.type === 'BUTTON' && action.name === 'set-markup'"/>
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action" :disabled="!rowsSelected"
                                                  v-on:set-discount="setDiscountForSelectedRules"
                                                  v-if="action.placement === 'GROUPBODY' && action.type === 'BUTTON' && action.name === 'set-discount'"/>
                                    <DropDownAction :key="action.name+'-'+actionIndex"
                                                    :action="action" :disabled="!rowsSelected"
                                                    v-on:delete-selection="confirmDeleteSelectedRules"
                                                    v-on:actualize-selection="actualizeSelectedRules"
                                                    v-on:copy-selection-to-managed-order="copySelectedRulesToManagedOrder"
                                                    v-if="action.placement === 'GROUPBODY' && action.type === 'DROPDOWN' && action.name === 'dropdown-selection'"/>
                                </template>
                            </b-form>
                        </div>
                    </div>
                </div>

                <!-- DOCUMENT SECTIONS -->

                <template v-for="section in data.sections">
                    <Table :key="section.id + '-' + sectionReload" header-class="light" footer-class="light"
                           :title="section.name" :rows="section.rules" :totals="section" :fields="fields.sectionRuleFields"
                           :totalFields="fields.sectionTotalFields" :actions="actions.manageActions" :state="state"
                           collapsible :collapsed="sectionCollapse[section.id]" :selection="rowsSelected"
                           v-on:actualize-row="actualizeRule"
                           v-on:add-rule="addRule(section.id)"
                           v-on:add-product="addProduct(section.id)"
                           v-on:add-sketchup="addSketchUp(section.id)"
                           v-on:collapse-table="collapseSection(section.id, $event)"
                           v-on:copy-selection="copySelectedRules(section.id)"
                           v-on:delete-row="deleteRule"
                           v-on:delete-section="deleteSection(section)"
                           v-on:delete-selection="confirmDeleteSelectedSectionRules(section)"
                           v-on:detail-row="detailRule"
                           v-on:inline-edit="inlineEdit"
                           v-on:move-selection="moveSelectedRules(section.id)"
                           v-on:set-section-sale-price="setSectionSalePrice(section.id, $event)"
                           v-on:sort-section="sortSection(section)"
                           v-on:toggle-row="toggleRow(section)"
                           v-on:update-row="updateRule"
                           v-on:update-section="updateSection(section)"/>
                </template>

                <!-- DOCUMENT FOOTER -->

                <div class="card">

                    <div class="card-header">
                        <b-form inline>
                            {{ $i18n.t('translations.Totals') }}
                            <template v-if="!state.readonly">
                                <template v-for="(action,index) in actions.manageActions">
                                    <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="!rowsSelected"
                                                  v-on:set-sale-price="setSalePrice"
                                                  v-if="action.placement === 'TOTALSHEADER' && action.type === 'BUTTON' && action.name === 'set-sale-price'"/>
                                </template>
                            </template>
                        </b-form>
                    </div>

                    <div class="card-body" style="padding: 1rem;">

                        <!-- MANAGED DOCUMENT FOOTER BODY -->

                        <table class="totals-table" v-if="type === 'managed_order'">
                            <thead>
                            <tr class="totals-table-super-header">
                                <th/>
                                <th colspan="5">{{ $i18n.tc('translations.Order', 1) }}</th>
                                <th colspan="4">{{ $i18n.tc('translations.Managed Order', 1) }}</th>
                            </tr>
                            <tr class="totals-table-header">
                                <th/>
                                <th>{{ $i18n.t('translations.Weight') }}</th>
                                <th>{{ $i18n.t('translations.Cost Price') }}</th>
                                <th>{{ $i18n.t('translations.Markup') }}</th>
                                <th>%</th>
                                <th>{{ $i18n.t('translations.Sale Price') }}</th>
                                <th>{{ $i18n.t('translations.Weight') }}</th>
                                <th>{{ $i18n.t('translations.Purchase Price') }}</th>
                                <th>{{ $i18n.t('translations.Margin') }} ({{ $i18n.t('translations.Gross') }})</th>
                                <th>%</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(row, rowLabel, rowIndex) in totalData.rows">
                                <tr :key="'tr-'+rowIndex">
                                    <td>{{ rowLabel }}</td>
                                    <td>{{ row['order_weight'] }} kg</td>
                                    <td class="st-cost">
                                        {{ row['order_cost_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_cost_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_cost_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">
                                        {{ row['order_markup_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_markup_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_markup_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">{{ row['order_markup'] }} %</td>
                                    <td class="st-sale">
                                        {{ row['order_sales_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_sales_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_sales_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td>{{ row['managed_order_weight'] }} kg</td>
                                    <td class="st-cost">
                                        {{ row['managed_order_purchase_price'] }}
                                        <template v-if="parseInt(row['managed_order_weight'])"> &#8226; {{ row['managed_order_purchase_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['managed_order_weight'])"> &#8226; {{ row['managed_order_purchase_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">
                                        {{ row['managed_order_margin_price'] }}
                                        <template v-if="parseInt(row['managed_order_weight'])"> &#8226; {{ row['managed_order_margin_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['managed_order_weight'])"> &#8226; {{ row['managed_order_margin_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">{{ row['managed_order_margin'] }} %</td>
                                </tr>
                            </template>
                            <tr>
                                <td>{{ $i18n.t('translations.Total') }}</td>
                                <td>{{ totalData.totals['order_weight'] }} kg</td>
                                <td class="st-cost">
                                    {{ totalData.totals['order_cost_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_cost_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">
                                    {{ totalData.totals['order_markup_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_markup_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">{{ totalData.totals['order_markup'] }} %</td>
                                <td class="st-sale">
                                    {{ totalData.totals['order_sales_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_sales_price_kg'] }}/kg</template>
                                </td>
                                <td>{{ totalData.totals['managed_order_weight'] }} kg</td>
                                <td class="st-cost">
                                    {{ totalData.totals['managed_order_purchase_price'] }}
                                    <template v-if="parseInt(totalData.totals['managed_order_weight'])"> &#8226; {{ totalData.totals['managed_order_purchase_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">
                                    {{ totalData.totals['managed_order_margin_price'] }}
                                    <template v-if="parseInt(totalData.totals['managed_order_weight'])"> &#8226; {{ totalData.totals['managed_order_margin_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">{{ totalData.totals['managed_order_margin'] }} %</td>
                            </tr>
                            </tbody>
                        </table>

                        <!-- DOCUMENT FOOTER BODY -->

                        <table class="totals-table" v-else>
                            <thead>
                            <tr class="totals-table-super-header">
                                <th/>
                                <th colspan="5">{{ $i18n.tc('translations.Cost Price', 1) }}</th>
                                <th colspan="3">{{ $i18n.tc('translations.Purchase Price', 1) }}</th>
                            </tr>
                            <tr class="totals-table-header">
                                <th/>
                                <th>{{ $i18n.t('translations.Weight') }}</th>
                                <th>{{ $i18n.t('translations.Cost Price') }}</th>
                                <th>{{ $i18n.t('translations.Markup') }}</th>
                                <th>%</th>
                                <th>{{ $i18n.t('translations.Sale Price') }}</th>
                                <th>{{ $i18n.t('translations.Purchase Price') }}</th>
                                <th>{{ $i18n.t('translations.Margin') }} ({{ $i18n.t('translations.Gross') }})</th>
                                <th>%</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(row, rowLabel, rowIndex) in totalData.rows">
                                <tr :key="'tr-'+rowIndex">
                                    <td>{{ rowLabel }}</td>
                                    <td>{{ row['order_weight'] }} kg</td>
                                    <td class="st-cost">
                                        {{ row['order_cost_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_cost_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_cost_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">
                                        {{ row['order_markup_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_markup_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_markup_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">{{ row['order_markup'] }} %</td>
                                    <td class="st-sale">
                                        {{ row['order_sales_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_sales_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_sales_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-cost">
                                        {{ row['order_purchase_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_purchase_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_purchase_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">
                                        {{ row['order_margin_price'] }}
                                        <template v-if="parseInt(row['order_weight'])"> &#8226; {{ row['order_margin_price_kg'] }}/kg</template>
                                        <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ row['order_margin_price_tkg'] }}/tkg</template>
                                    </td>
                                    <td class="st-margin">{{ row['order_margin'] }} %</td>
                                </tr>
                            </template>
                            <tr>
                                <td>{{ $i18n.t('translations.Total') }}</td>
                                <td>{{ totalData.totals['order_weight'] }} kg</td>
                                <td class="st-cost">
                                    {{ totalData.totals['order_cost_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_cost_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">
                                    {{ totalData.totals['order_markup_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_markup_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">{{ totalData.totals['order_markup'] }} %</td>
                                <td class="st-sale">
                                    {{ totalData.totals['order_sales_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_sales_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-cost">
                                    {{ totalData.totals['order_purchase_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_purchase_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">
                                    {{ totalData.totals['order_margin_price'] }}
                                    <template v-if="parseInt(totalData.totals['order_weight'])"> &#8226; {{ totalData.totals['order_margin_price_kg'] }}/kg</template>
                                </td>
                                <td class="st-margin">{{ totalData.totals['order_margin'] }} %</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                    <div class="card-footer light">
                        <template v-for="(action,index) in actions.manageActions">
                            <DropDownAction :key="action.name + '-' + index" :action="action"
                                            v-on:print-document="printDocumentOptions"
                                            v-on:download-event="download"
                                            v-if="action.placement === 'TOTALSFOOTER' && action.type === 'DROPDOWN'"/>
                        </template>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
import ActualizeRulesDialog from "@/components/dialogs/ActualizeRulesDialog";
import ButtonAction from "@/components/actions/ButtonAction";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";
import DropDownAction from "@/components/actions/DropDownAction";
import FileDownload from "@/mixins/FileDownload";
import LinkAction from "@/components/actions/LinkAction";
import PrintDocumentOptionsDialog from "@/components/dialogs/PrintDocumentOptionsDialog";
import RecordDialog from "@/components/dialogs/RecordDialog";
import RouteAction from "@/components/actions/RouteAction.vue";
import SelectAction from "@/components/actions/SelectAction.vue";
import SelectField from "@/components/forms/fields/SelectField.vue";
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: "Document",
    components: {
        ActualizeRulesDialog,
        ButtonAction,
        ConfirmationDialog,
        DropDownAction,
        LinkAction,
        PrintDocumentOptionsDialog,
        RecordDialog,
        RouteAction,
        SelectAction,
        SelectField,
        Table,
    },
    props: ['name', 'document', 'icon', 'type', 'api', 'state', 'userLanguage', 'languages'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            rowsSelected: false,
            data: null,
            totalData: null,
            showDocumentCardFooter: true,
            showSectionsHeader: true,
            showCreateButton: false,
            fields: null,
            actions: null,
            placeholder: this.$i18n.t('translations.Enter Category Name'),
            showActualizeRulesDialog: false,
            actualizeRulesDialogParameters: {
                size: null,
                title: null,
                api: null,
                ids: null,
                data: null,
                fields: null,
            },
            confirmationDialogParameters: {
                type: '',
                title: '',
                message: '',
                ok_event: 'ok',
                ok_text: 'OK',
                cancel_event: 'cancel',
                cancel_text: this.$i18n.t("translations.Cancel"),
                data: null,
            },
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                ok_event: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
            printDocumentOptionsDialogParameters: {
                document: null,
            },
            showPrintDocumentOptionsDialog: false,
            sectionReload: 1,
            sectionCollapse: {},
        }
    },
    watch: {
        $route() {
            this.fetchData(parseInt(this.$route.params.id));
        }
    },
    created() {
        if (this.sketchUpBrowser) {
            window.vm.Tool = this;
        }
        this.fetchData(parseInt(this.$route.params.id));
    },
    methods: {
        actualizeRule(data) {
            this.state.loading = true;
            this.$http.get(this.api + '/get_actualized_rule/' + data.id).then((res) => {
                this.actualizeRulesDialogParameters.size = this.type === 'managed_order' ? 'lg' : 'xl';
                this.actualizeRulesDialogParameters.title = this.$i18n.t("translations.Actualize Rule");
                this.actualizeRulesDialogParameters.api = this.api + '/actualize_rules';
                this.actualizeRulesDialogParameters.ids = [data.id];
                this.actualizeRulesDialogParameters.data = res.data.data;
                this.actualizeRulesDialogParameters.fields = this.fields.actualizeRuleFields;
                this.showActualizeRulesDialog = true;
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:actualizeRule():error:", error);
            });
        },
        actualizeSelectedRules() {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.actualizeRulesDialogParameters.size = this.type === 'managed_order' ? 'lg' : 'xl';
            this.actualizeRulesDialogParameters.title = this.$i18n.t("translations.Actualize Selected Rows");
            this.actualizeRulesDialogParameters.api = this.api + '/actualize_rules';
            this.actualizeRulesDialogParameters.ids = ruleIds;
            this.actualizeRulesDialogParameters.data = null;
            this.actualizeRulesDialogParameters.fields = this.fields.actualizeRuleFields;
            this.showActualizeRulesDialog = true;
            this.state.loading = false;
        },
        addSection(data) {
            this.state.loading = true;
            this.$http.post(this.api + '/add_section/' + this.data.id, data, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:addSection():error:", error);
            });
        },
        addProduct(sectionId) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Product", 1);
            this.recordDialogParameters.api = this.api + '/add_rule_from_product/' + this.data.id + '/' + sectionId;
            this.recordDialogParameters.fields = this.fields.addProductRuleFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-set-data';
            this.showRecordDialog = true;
        },
        addRule(sectionId) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Rule", 1);
            this.recordDialogParameters.api = this.api + '/add_rule/' + this.data.id + '/' + sectionId;
            this.recordDialogParameters.fields = this.fields.addRuleFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-set-data';
            this.showRecordDialog = true;
        },
        addSketchUp(sectionId) {
            this.state.loading = true;
            sketchup.getProducts(sectionId);
        },
        checkLayout() {
            this.showDocumentCardFooter = false;
            this.showSectionsHeader = false;
            this.showCreateButton = false;
            this.actions.manageActions.forEach(action => {
                if (action.placement === 'DOCUMENTCARDFOOTERLEFT' || action.placement === 'DOCUMENTCARDFOOTERRIGHT') {
                    this.showDocumentCardFooter = true;
                }
                if (action.placement === 'GROUPHEADERRIGHT' || action.placement === 'GROUPBODY') {
                    this.showSectionsHeader = true;
                }
                if (action.placement === 'DOCUMENTHEADERRIGHT') {
                    this.showCreateButton = true;
                }
            });
        },
        collapseSection(sectionId, collapsed) {
            this.sectionCollapse[sectionId] = collapsed;
        },
        confirmDeleteSelectedSectionRules(section) {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.t("translations.Delete Selected Rows In This Category");
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to delete the selected rows in this category?");
            this.confirmationDialogParameters.data = section;
            this.confirmationDialogParameters.ok_event = 'delete-selected-section-rules';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Delete");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.$bvModal.show('confirmation-dialog');
        },
        confirmDeleteSelectedRules() {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.t("translations.Delete Selected Rows");
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to delete the selected rows?");
            this.confirmationDialogParameters.ok_event = 'delete-selected-rules';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Delete");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.$bvModal.show('confirmation-dialog');
        },
        copyAs(choice) {
            this.state.loading = true;
            this.$http.get(choice.api + '/' + this.data.id).then((res) => {
                this.$router.push({name: choice.routename, params: {id: res.data.data.id}});
            }).catch((error) => {
                console.log("Document:copyAs():error:", error);
            });
        },
        copySelectedRules(sectionId) {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/copy_selected_rules/' + this.data.id + '/' + sectionId, {'ruleIds': ruleIds}, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:copySelectedRules():error:", error);
            });
        },
        copySelectedRulesToManagedOrder() {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/copy_selected_rules_to_managed_order/' + this.data.id, {'ruleIds': ruleIds}, {}).then(() => {
                this.$router.push({name: 'managed_orders', params: {id: this.data.id}});
            }).catch((error) => {
                console.log("Document:copySelectedRulesToManagedOrder():error:", error);
            });
        },
        createDocument() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations." + this.document, 1);
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.fields.documentFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-create-document';
            this.showRecordDialog = true;
        },
        createEstimate() {
            this.state.loading = true;
            this.$http.get(this.api + '/copy_estimate/' + this.data.id).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:createEstimate():error:", error);
            });
        },
        deleteRule(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = data.view;
            this.recordDialogParameters.api = this.api + '/delete_rule/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.ruleFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-set-data';
            this.showRecordDialog = true;
        },
        deleteSection(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = data.name;
            this.recordDialogParameters.api = this.api + '/delete_section/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.sectionFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-set-data';
            this.showRecordDialog = true;
        },
        deleteSelectedSectionRules(section) {
            this.state.loading = true;
            let ruleIds = [];
            section.rules.forEach(rule => {
                if (rule.selected) {
                    ruleIds.push(rule.id);
                }
            });
            this.$http.post(this.api + '/delete_selected_rules/' + this.data.id, {'ruleIds': ruleIds}, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:deleteSelectedSectionRules():error:", error);
            });
        },
        deleteSelectedRules() {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/delete_selected_rules/' + this.data.id, {'ruleIds': ruleIds}, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:deleteSelectedRules():error:", error);
            });
        },
        detailRule(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = data.view;
            this.recordDialogParameters.api = null;
            this.recordDialogParameters.fields = this.fields.ruleFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = null;
            this.showRecordDialog = true;
        },
        documentSelectInput(data) {
            if (data['id'] !== parseInt(this.$route.params.id)) {
                this.$router.push({params: {id: data['id']}});
            }
        },
        download(choice) {
            this.state.loading = true;
            let sectionIds = {};
            this.data.sections.forEach(section => {
                let ruleIds = [];
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
                if (ruleIds.length) {
                    sectionIds[section.id] = ruleIds;
                }
            });
            this.$http.post(choice.api + '/' + this.data.id, {'sectionIds': sectionIds}, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:download():error:", error);
            });
        },
        fetchData(id) {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get(this.api + '/manage/' + id, {}).then((res) => {
                this.setData(res.data);
                this.pageLoaded = !!res.data.data;
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:fetchData():error:", error);
            });
        },
        getProductsCallback(id, data) {
            let entities = Object.freeze(JSON.parse(data));
            let components = [];
            entities.forEach(entity => {
                let index = components.findIndex(item => item.attribute_naam === entity.attribute_naam);
                if (index === -1) {
                    components.push({
                        'attribute_naam': entity.attribute_naam,
                        'attribute_eenheid': entity.attribute_eenheid,
                        'attribute_hoeveelheid': entity.attribute_hoeveelheid,
                        'attribute_rivetten': entity.attribute_rivetten,
                        'attribute_zgewicht': entity.attribute_zgewicht,
                        'attribute_zkostprijs': entity.attribute_zkostprijs,
                        'attribute_zmarge': entity.attribute_zmarge,
                        'attribute_zzkorting': entity.attribute_zzkorting,
                    });
                } else {
                    components[index].attribute_hoeveelheid += entity.attribute_hoeveelheid;
                }
            });
            this.$http.post(this.api + '/add_rule_from_sketchup/' + this.data.id + '/' + id, {
                id: id,
                components: JSON.stringify(components)
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:getProductsCallback():error:", error);
            });
        },
        inlineEdit(data) {
            this.sectionReload++;
            this.data = data.data;
            this.state.loading = false;
        },
        moveSelectedRules(sectionId) {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/move_selected_rules/' + this.data.id + '/' + sectionId, {'ruleIds': ruleIds}, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:moveSelectedRules():error:", error);
            });
        },
        printDocument(data) {
            this.state.loading = true;
            this.$http.post(data.choice.api + '/' + this.data.id, {options: data.options}, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(data.choice, res.data);
                this.fetchData(this.data.id);
            }).catch((error) => {
                console.log("Document:printDocument():error:", error);
            });
        },
        printDocumentOptions(choice) {
            this.printDocumentOptionsDialogParameters.choice = choice;
            this.printDocumentOptionsDialogParameters.document = this.data;
            this.showPrintDocumentOptionsDialog = true;
        },
        selectView(option) {
            this.$router.push({name: option.routename, params: {id: this.data.id}});
        },
        setData(data) {
            this.rowsSelected = false;
            this.sectionReload++;
            if (data.data) {
                let tmp = data.data;
                tmp.sections.forEach(section => {
                    section.rules.filter(rule => rule.selected = false);
                    section.selected = false;
                })
                this.data = tmp;
                this.state.readonly = (this.type === 'managed_order' ? data.data.managed_order_locked === 1 : data.data.locked === 1);
            } else {
                this.data = null;
                this.state.readonly = false;
            }
            this.actions = data.actions;
            this.fields = data.fields;
            this.totalData = data.totalData;
            this.checkLayout();
        },
        setDiscountForSelectedRules(data) {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/set_discount_for_selected_rules/' + this.data.id, {
                'discount': data.discount,
                'ruleIds': ruleIds
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:setDiscountForSelectedRules():error:", error);
            });
        },
        setMarkupForSelectedRules(data) {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/set_markup_for_selected_rules/' + this.data.id, {
                'markup': data.markup,
                'ruleIds': ruleIds
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:setMarkupForSelectedRules():error:", error);
            });
        },
        setQuantityForSelectedRules(data) {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/set_quantity_for_selected_rules/' + this.data.id, {
                'quantity': data.quantity,
                'ruleIds': ruleIds
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:setQuantityForSelectedRules():error:", error);
            });
        },
        setSalePrice(data) {
            this.state.loading = true;
            let ruleIds = [];
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        ruleIds.push(rule.id);
                    }
                });
            });
            this.$http.post(this.api + '/set_sale_price/' + this.data.id, {
                'salePrice': data.sale_price,
                'ruleIds': ruleIds
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:setSalePrice():error:", error);
            });
        },
        setSectionSalePrice(sectionId, data) {
            this.state.loading = true;
            this.$http.post(this.api + '/set_section_sale_price/' + this.data.id + '/' + sectionId, {
                'salePrice': data.sale_price,
                'ruleIds': data.ids
            }, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:setSectionSalePrice():error:", error);
            });
        },
        sortSection(section) {
            this.state.loading = true;
            this.$http.get(this.api + '/sort_section/' + section.id).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("Document:sortSection():error:", error);
            });
        },
        successCreateDocument(data) {
            this.fetchData(data.data.id);
        },
        toggleRow() {
            this.rowsSelected = false;
            this.data.sections.forEach(section => {
                section.rules.forEach(rule => {
                    if (rule.selected) {
                        this.rowsSelected = true;
                    }
                });
            });
        },
        updateSection(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = data.name;
            this.recordDialogParameters.api = this.api + '/update_section/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.sectionFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-set-data';
            this.showRecordDialog = true;
        },
        updateCustomer() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.project_id.customer_id.label;
            this.recordDialogParameters.api = '/contacts';
            this.recordDialogParameters.fields = this.fields.customerFields;
            this.recordDialogParameters.data = this.data.project_id.customer_id;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateDocument() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.label;
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.fields.documentFields;
            this.recordDialogParameters.data = this.data;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateProject() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.project_id.label;
            this.recordDialogParameters.api = '/projects';
            this.recordDialogParameters.fields = this.fields.projectFields;
            this.recordDialogParameters.data = this.data.project_id;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateRule(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = data.view;
            this.recordDialogParameters.api = this.api + '/update_rule';
            this.recordDialogParameters.fields = this.fields.ruleFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-set-data';
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
.sections-header-sticky {
    position: sticky;
    top: 45px;
    z-index: 500;
}

.totals-table {
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
}

.totals-table-super-header {
    text-align: center;
}

.totals-table-super-header > th:nth-child(2) {
    background-color: rgba(0, 255, 0, 0.15);
}

.totals-table-super-header > th:nth-child(3) {
    background-color: rgba(0, 0, 255, 0.15);
}

.totals-table-header {
    text-align: right;
}

.totals-table > tbody > tr {
    text-align: right;
}

.totals-table > tbody > tr > td:first-child {
    font-weight: bold;
}

.totals-table > tbody > tr:last-child > td:nth-child(n+2) {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
}
</style>
