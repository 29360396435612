import { render, staticRenderFns } from "./ViewComponentDialog.vue?vue&type=template&id=290ef896&scoped=true&"
import script from "./ViewComponentDialog.vue?vue&type=script&lang=js&"
export * from "./ViewComponentDialog.vue?vue&type=script&lang=js&"
import style0 from "./ViewComponentDialog.vue?vue&type=style&index=0&id=290ef896&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290ef896",
  null
  
)

export default component.exports