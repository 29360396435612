<template>
    <b-modal id="view-component-dialog" size="custom"
             no-close-on-backdrop
             v-on:hidden="$emit('hidden')"
             v-on:shown="shown">

        <template #modal-title>
            <font-awesome-icon :icon="icon" size="sm" class="mr-2" v-if="icon"/>
            {{ title }}
        </template>

        <table style="margin-bottom: 1rem; border-collapse: collapse; width: 100%;" v-if="actions">
            <tr>
                <td>
                    <div class="input-group input-group-sm">
                        <template v-for="(action,actionIndex) in actions">
                            <div :key="action.name+'-'+actionIndex" class="input-group-append"
                                 v-if="action.placement === 'TABLEHEADERLEFT'">
                                <ButtonAction :action="action" forward-events
                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                              v-if="action.type === 'BUTTON'"/>
                            </div>
                        </template>
                    </div>
                </td>
                <td>
                    <div class="float-right">
                        <div class="input-group input-group-sm">
                            <template v-for="(action,actionIndex) in actions">
                                <div :key="action.name+'-'+actionIndex" class="input-group-append"
                                     v-if="action.placement === 'TABLEHEADERRIGHT'">
                                    <ButtonAction :action="action" forward-events
                                                  v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                  v-if="action.type === 'BUTTON'"/>
                                </div>
                            </template>
                        </div>
                    </div>
                </td>
            </tr>
        </table>

        <div style="text-align: center;">
            <canvas id="viewer" ref="viewer" width="1024" height="768"/>
        </div>

        <template #modal-footer="{ ok }">
            <b-button pill variant="dark" class="pl-5 pr-5" v-on:click="ok()">
                {{ $i18n.t('translations.Close') }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";

export default {
    name: "ViewComponentDialog",
    components: {
        ButtonAction,
    },
    props: {
        icon: {type: Array, default: () => ['fas', 'eye']},
        title: {type: String, default: ''},
        actions: {type: Array, default: null},
        component: {type: Object, default: () => []},
    },
    mounted() {
        this.$bvModal.show('view-component-dialog');
    },
    methods: {
        drawComponent() {
            function scaleX(x) {
                return margin + parseFloat(x) * scaleFactor + offsetX;
            }

            function scaleY(y) {
                return margin + canvasHeight - (parseFloat(y) * scaleFactor + offsetY);
            }

            function circle(center, radius, color = standardColor) {
                ctx.beginPath();
                ctx.arc(scaleX(center.x), scaleY(center.y), parseFloat(radius) * scaleFactor, 0, 2 * Math.PI);
                ctx.strokeStyle = color;
                ctx.lineWidth = standardLineWidth;
                ctx.stroke();
            }

            function line(from, to, lineWidth = standardLineWidth, color = standardColor) {
                ctx.beginPath();
                ctx.moveTo(scaleX(from.x), scaleY(from.y));
                ctx.lineTo(scaleX(to.x), scaleY(to.y));
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;
                ctx.stroke();
            }

            function max(max, value) {
                return (max == null || max < value) ? value : max;
            }

            function min(min, value) {
                return (min == null || min > value) ? value : min;
            }

            const margin = 25;
            const foldColor = "Red";
            const foldLineWidth = 1;
            const standardColor = "DarkGray";
            const standardLineWidth = 1;
            const allowanceColor = "LimeGreen";
            const allowanceLineWidth = 5;
            const deductionColor = "LimeGreen"
            const deductionRadius = 10;

            const canvas = this.$refs['viewer'];
            const ctx = canvas.getContext("2d");
            const canvasWidth = ctx.canvas.width - 2 * margin;
            const canvasHeight = ctx.canvas.height - 2 * margin;

            let minX = null, minY = null, maxX = null, maxY = null;
            this.component.loops.forEach(l => {
                if (l.circle) {
                    minX = min(minX, parseFloat(l.arccurve.x) - parseFloat(l.arccurve.radius));
                    minY = min(minY, parseFloat(l.arccurve.y) - parseFloat(l.arccurve.radius));
                    maxX = max(maxX, parseFloat(l.arccurve.x) + parseFloat(l.arccurve.radius));
                    maxY = max(maxY, parseFloat(l.arccurve.y) + parseFloat(l.arccurve.radius));
                } else {
                    l.vertices.forEach(v => {
                        if (v.allowance_deduction < 0) {
                            minX = min(minX, parseFloat(v.x) - 8);
                            minY = min(minY, parseFloat(v.y) - 8);
                            maxX = max(maxX, parseFloat(v.x) + 8);
                            maxY = max(maxY, parseFloat(v.y) + 8);
                        }
                        minX = min(minX, parseFloat(v.x));
                        minY = min(minY, parseFloat(v.y));
                        maxX = max(maxX, parseFloat(v.x));
                        maxY = max(maxY, parseFloat(v.y));
                    });
                }
            });
            const componentWidth = maxX - minX;
            const componentHeight = maxY - minY;
            let scaleFactor;
            let offsetX, offsetY;
            if (canvasWidth / componentWidth > canvasHeight / componentHeight) {
                scaleFactor = canvasHeight / componentHeight;
                offsetX = -minX * scaleFactor + (canvasWidth - componentWidth * scaleFactor) / 2;
                offsetY = -minY * scaleFactor;
            } else {
                scaleFactor = canvasWidth / componentWidth;
                offsetX = -minX * scaleFactor;
                offsetY = -minY * scaleFactor + (canvasHeight - componentHeight * scaleFactor) / 2;
            }

            this.component.loops.forEach(l => {
                if (l.circle) {
                    circle(l.arccurve, l.arccurve.radius);
                } else {
                    let from = null;
                    l.vertices.forEach(v => {
                        if (v.allowance_deduction < 0) {
                            circle(v, deductionRadius / scaleFactor, deductionColor);
                        }
                        if (from) {
                            if (from.allowance_deduction > 0 || v.allowance_deduction > 0) {
                                line(from, v, allowanceLineWidth, allowanceColor);
                            } else {
                                line(from, v);
                            }
                        }
                        from = v;
                    });
                    if (from.allowance_deduction > 0 || l.vertices[0].allowance_deduction > 0) {
                        line(from, l.vertices[0], allowanceLineWidth, allowanceColor);
                    } else {
                        line(from, l.vertices[0]);
                    }
                }
            });

            if ('folds' in this.component) {
                this.component.folds.forEach(f => {
                    f.markers.forEach(m => {
                        line({x: m.x1, y: m.y1}, {x: m.x2, y: m.y2}, foldLineWidth, foldColor);
                    })
                });
            }
        },
        handleForwardedActionEvent(action, eventData) {
            this.$emit(eventData.event, eventData.data);
        },
        shown() {
            if (this.component && ('loops' in this.component)) {
                this.drawComponent();
            }
        },
    }
}
</script>

<style scoped>
>>> .modal .modal-custom {
    max-width: calc(1024px + 2rem);
    width: calc(1024px + 2rem);
}
</style>
