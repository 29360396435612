<template>
    <div>

        <ComponentDialog v-if="showComponentDialog" v-bind="componentDialogParameters"
                         v-on:hidden="showComponentDialog = false"
                         v-on:select-component="selectComponent"
                         v-on:zoom-component="zoomComponent"
                         v-on:zoom-reset="zoomReset"/>

        <ViewComponentDialog v-if="showViewComponentDialog" v-bind="viewComponentDialogParameters"
                             v-on:hidden="showViewComponentDialog = false"
                             v-on:select-component="selectComponent(viewComponentDialogParameters.component)"
                             v-on:zoom-component="zoomComponent(viewComponentDialogParameters.component)"
                             v-on:zoom-reset="zoomReset"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="width: 100%;">
                    <tr>
                        <td style="padding: 0 0 0 1rem; width: 280px;">
                            <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                            {{ $i18n.tc('translations.' + name, 2).toUpperCase() }}
                        </td>
                        <template v-if="serverDataLoaded">
                            <td style="width: 1rem;"></td>
                            <template v-if="data.model.project">
                                <td style="padding: 0 10px; border: solid 1px lightgray; width: 30%;" v-if="data.model.phase">
                                    {{ data.model.phase.label }}
                                </td>
                                <td style="width: 30%;" v-else/>
                                <td style="width: 1rem;"/>
                                <td style="padding: 0 10px; border: solid 1px lightgray;">
                                    <LinkAction :action="actions.modelLinkAction"/>
                                </td>
                                <td style="padding: 0; width: 90px; text-align: right;">
                                    (#{{ data.model.phase ? data.model.phase.id : (data.model.order ? data.model.order.id : data.model.project.id) }})
                                </td>
                            </template>
                            <template v-else>
                                <td style="padding: 0 10px; border: solid 1px lightgray;">
                                    <router-link :to="{name: 'order_list'}" class="st-cardheader-link">
                                        {{ $i18n.t('translations.SketchUp model is not linked to a project') }}!
                                    </router-link>
                                </td>
                            </template>
                            <td style="width: 1rem;"></td>
                        </template>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="serverDataLoaded">

                <div class="card" style="border: none;">
                    <div class="card-header light" style="border: none;">
                        <div class="float-right" style="display: inline-block;">
                            <template v-for="(action,actionIndex) in actions.document">
                                <ButtonAction :key="action.name+'-'+actionIndex"
                                              :action="action" :disabled="state.disabled"
                                              v-on:generate-dxf-files="launchPunchPlates"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </div>
                    </div>
                </div>

                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination pagination-always-visible no-state-loading autofocus
                       :lines-per-page=35 th-actions-style="width:185px;"
                       :rows="processedComponentList"
                       :fields="fields.table" :actions="actions.table" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:delete-selection="deleteSelection"
                       v-on:detail-components="detailComponents"
                       v-on:download-event="download"
                       v-on:make-unique="makeComponentsUnique"
                       v-on:refresh="getPunchPlates2"
                       v-on:reset-ignore-flipped="resetIgnoreFlipped"
                       v-on:select-all="selectAll"
                       v-on:select-component="selectComponent"
                       v-on:set-ignore-flipped="setIgnoreFlipped"
                       v-on:toggle-bend-allowance-deduction="toggleBendAllowanceDeduction"
                       v-on:ignore-flipped-for-unfoled-plates="ignoreFlippedForUnfoldedPlates"
                       v-on:view-component="viewComponent"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>

            </div>

        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import ComponentDialog from "@/components/dialogs/sketchup/ComponentDialog.vue";
import FileDownload from "@/mixins/FileDownload";
import LinkAction from "@/components/actions/LinkAction.vue";
import Table from "@/components/Table";
import ViewComponentDialog from "@/components/dialogs/sketchup/ViewComponentDialog.vue";

/* global sketchup:false */

export default {
    name: 'PunchPlates2',
    components: {
        ButtonAction,
        ComponentDialog,
        LinkAction,
        Table,
        ViewComponentDialog,
    },
    props: ['name', 'icon', 'api', 'state'],
    mixins: [FileDownload],
    data() {
        return {
            serverDataLoaded: false,
            tableReload: 0,
            sketchUpToolData: null,
            data: null,
            fields: null,
            actions: null,
            rawComponentList: [],
            processedComponentList: [],
            enableBendAllowanceDeduction: true,
            ignoreFlippedDefinitions: [],
            showComponentDialog: false,
            componentDialogParameters: {
                title: null,
                actions: null,
                fields: null,
                rows: null,
            },
            showViewComponentDialog: false,
            viewComponentDialogParameters: {
                title: null,
                actions: null,
                component: null,
            },
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        deleteSelection(data) {
            data.ids.forEach(id => {
                let idx = this.processedComponentList.findIndex(component => component.id === id);
                this.processedComponentList.splice(idx, 1);
            });
            this.tableReload++;
        },
        detailComponents(component) {
            let rows = [];
            component.entity_ids.forEach(entityID => {
                let instanceIdx = rows.findIndex(row => row.entity_id === entityID);
                if (instanceIdx === -1) {
                    rows.push({
                        id: rows.length + 1,
                        entity_id: entityID,
                        instances: 1,
                        flipped: component.flipped,
                        flipped_style: component.flipped_style,
                    });
                } else {
                    rows[instanceIdx].instances++;
                }
            });
            this.componentDialogParameters.title = component.definition;
            this.componentDialogParameters.actions = this.actions.detail;
            this.componentDialogParameters.fields = this.fields.detail;
            this.componentDialogParameters.rows = rows;
            this.showComponentDialog = true;
        },
        download(choice) {
            this.state.loading = true;
            let components = [];
            this.processedComponentList.forEach(component => {
                components.push({
                    definition: component.definition,
                    components_in_selection: component.components_in_selection,
                    instances_in_model: component.instances_in_model,
                    instances_in_selection: component.instances_in_selection,
                    cnt_folds: component.cnt_folds,
                    angles: component.angles,
                    bend_allowances_deductions: component.bend_allowances_deductions,
                    thickness: component.thickness,
                    cnt_faces: component.cnt_faces,
                    circles: component.circles,
                    orientation_errors: component.orientation_errors,
                    flipped: component.flipped,
                });
            });
            this.$http.post(choice.api, {
                'components': components,
            }, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("PunchPlates2:download():error:", error);
            });
        },
        fetchData() {
            this.$http.get(this.api + '/manage_punchplates2/' + this.sketchUpToolData.project_id + '/' + this.sketchUpToolData.order_id + '/' + this.sketchUpToolData.phase_id, {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.data = res.data.data;
                this.serverDataLoaded = true;
                sketchup.getPunchPlates2(this.data.filterMaterial, this.enableBendAllowanceDeduction ? this.data.bendAllowanceDeduction : null);
            }).catch((error) => {
                console.log("PunchPlates2:fetchData():error:", error);
            });
        },
        getPunchPlates2(data) {
            this.state.loading = true;
            this.data.filterMaterial = data['filter_material'];

            this.$http.post('/sketchup/add_material_filter_option', {
                filter_material: this.data.filterMaterial,
            }, {}).then((res) => {
                res.data.actions.table.forEach(action => {
                    let idx = this.actions.table.findIndex(a => a.name === action.name);
                    if (idx > -1) {
                        this.actions.table[idx] = action;
                    }
                });
                sketchup.getPunchPlates2(this.data.filterMaterial, this.enableBendAllowanceDeduction ? this.data.bendAllowanceDeduction : null);
            }).catch((error) => {
                console.log("PunchPlates2:getPunchPlates2():error:", error);
            });
        },
        getPunchPlates2Callback(entities) {
            this.rawComponentList = JSON.parse(entities);
            this.processComponentList();
        },
        getToolDataCallback(data) {
            this.sketchUpToolData = data;
            this.fetchData();
        },
        ignoreFlippedForUnfoldedPlates() {
            this.state.loading = true;
            this.processedComponentList.forEach(component => {
                if (component.cnt_folds === 0) {
                    let idx = this.ignoreFlippedDefinitions.indexOf(component.definition);
                    if (idx === -1) {
                        this.ignoreFlippedDefinitions.push(component.definition);
                    }
                }
            });
            this.processComponentList();
        },
        launchPunchPlates(data) {
            this.state.loading = true;

            // XML Document
            let xml = document.implementation.createDocument(null, "PunchPlates");
            let elements = xml.getElementsByTagName("PunchPlates");

            // Options element
            let options = xml.createElement("Options");
            options.setAttribute("version", this.data.punchPlateUtilityVersion);
            if ('polygon-offset' in data) {
                options.setAttribute("polygonOffset", data['polygon-offset']);
            } else {
                options.setAttribute("polygonOffset", '0');
            }
            if ('filename-prefix' in data) {
                options.setAttribute("fileNamePrefix", data['filename-prefix']);
            } else {
                options.setAttribute("fileNamePrefix", '');
            }
            if ('label-prefix' in data) {
                options.setAttribute("labelPrefix", data['label-prefix']);
            } else {
                options.setAttribute("labelPrefix", '');
            }
            options.setAttribute("targetDir", this.data.dropBoxLocation);
            elements[0].appendChild(options);

            let noRowsSelected = true;
            this.processedComponentList.forEach(punchplate => {
                if (punchplate.selected) {
                    noRowsSelected = false;
                }
            });
            this.processedComponentList.forEach(p => {
                if (noRowsSelected || p.selected) {
                    let punchplate = xml.createElement("PunchPlate");
                    punchplate.setAttribute("definition", p.definition);
                    punchplate.setAttribute("thickness", p.thickness);
                    punchplate.setAttribute("quantity", p.instances_in_selection);
                    if (p.fileNameSuffix) {
                        punchplate.setAttribute("fileNameSuffix", p.fileNameSuffix);
                    }
                    let face = xml.createElement("Face");

                    p.loops.forEach(l => {
                        let loop = xml.createElement("Loop");
                        loop.setAttribute("outer", l.outer);
                        loop.setAttribute("circle", l.circle);
                        if (l.circle) {
                            let arccurve = xml.createElement("ArcCurve");
                            arccurve.setAttribute("x", l.arccurve.x);
                            arccurve.setAttribute("y", l.arccurve.y);
                            arccurve.setAttribute("radius", l.arccurve.radius);
                            loop.appendChild(arccurve);
                        } else {
                            l.vertices.forEach(v => {
                                let vertex = xml.createElement("Vertex");
                                vertex.setAttribute("x", v.x);
                                vertex.setAttribute("y", v.y);
                                loop.appendChild(vertex);
                            });
                        }
                        face.appendChild(loop);
                    });

                    p.folds.forEach(f => {
                        f.markers.forEach(m => {
                            let fold = xml.createElement("Fold");
                            fold.setAttribute("x1", m.x1);
                            fold.setAttribute("y1", m.y1);
                            fold.setAttribute("x2", m.x2);
                            fold.setAttribute("y2", m.y2);
                            face.appendChild(fold);
                        })
                    });

                    punchplate.appendChild(face);
                    elements[0].appendChild(punchplate);
                }
            });

            let sXml = (new XMLSerializer()).serializeToString(xml);
            sketchup.launchPunchPlates(process.env.VUE_APP_TEMP_DIR, process.env.VUE_APP_PUNCHPLATES_DIR, sXml);
        },
        launchPunchPlatesCallback() {
            this.state.loading = false;
        },
        makeComponentsUnique(component) {
            this.state.loading = true;
            sketchup.makeComponentsUnique(component.entity_ids);
        },
        makeComponentsUniqueCallback() {
            sketchup.getPunchPlates2(this.data.filterMaterial, this.enableBendAllowanceDeduction ? this.data.bendAllowanceDeduction : null);
        },
        processComponentList() {
            this.$worker.run((rawComponentList, ignoreFlippedDefinitions, enableBendAllowanceDeduction) => {
                let newProcessedComponentList = [];

                rawComponentList.forEach(component => {
                    let ignoreFlipped = (ignoreFlippedDefinitions.indexOf(component.definition) > -1);
                    let idx = newProcessedComponentList.findIndex(item => item.definition === component.definition);
                    if (ignoreFlipped && idx > -1) {
                        newProcessedComponentList[idx].entity_ids = newProcessedComponentList[idx].entity_ids.concat(component.entity_ids);
                        newProcessedComponentList[idx].components_in_selection = new Set(newProcessedComponentList[idx].entity_ids).size;
                        newProcessedComponentList[idx].instances_in_selection += component.instances_in_selection;
                    } else {
                        let newComponent = component;
                        newComponent.id = newProcessedComponentList.length + 1;
                        newComponent.selected = false;
                        newComponent.components_in_selection = new Set(newComponent.entity_ids).size;
                        newComponent.cnt_folds_f = newComponent.cnt_folds > 0 ? newComponent.cnt_folds : '';
                        newComponent.orientation_errors_f = newComponent.orientation_errors > 0 ? newComponent.orientation_errors : '';
                        newComponent.fileNameSuffix = null;

                        newComponent.hide_set_ignore_flipped = true;
                        newComponent.hide_reset_ignore_flipped = !ignoreFlipped;
                        newComponent.hide_make_unique = true;
                        newComponent.ignore_flipped = ignoreFlipped;
                        if (ignoreFlipped) {
                            newComponent.flipped = '!';
                            newComponent.flipped_style = "background: orange; color: white;";
                        } else {
                            newComponent.flipped = newComponent.is_flipped ? 'X' : '';
                        }

                        let circles = [];
                        newComponent.loops.forEach(loop => {
                            if (loop.circle) {
                                let diameter = Math.round(loop.arccurve.radius * 200) / 100;
                                let i = circles.findIndex(circle => circle.diameter === diameter);
                                if (i === -1) {
                                    circles.push({
                                        'diameter': diameter,
                                        'count': 1,
                                    })
                                } else {
                                    circles[i].count++;
                                }
                            }
                        });
                        newComponent.circles = '';
                        circles.forEach(circle => {
                            let c = circle.count + ' x ' + circle.diameter.toString().replace('.', ',') + 'mm';
                            newComponent.circles += (newComponent.circles ? ', ' + c : c);
                        });

                        newComponent.angles = '';
                        newComponent.bend_allowances_deductions = '';
                        newComponent.folds.forEach(fold => {
                            if (fold.concave !== null) {
                                newComponent.angles += (newComponent.angles ? ', ' : '') + (fold.concave ? fold.angle : 360 - fold.angle) + '°';
                                if (enableBendAllowanceDeduction) {
                                    newComponent.bend_allowances_deductions += (newComponent.bend_allowances_deductions ? ', ' : '') + (fold.allowance_deduction ? fold.allowance_deduction.toString().replace('.', ',') + 'mm' : 'n/a');
                                }
                            }
                        });

                        if (newComponent.fold_errors) {
                            newComponent.folds_style = "background: red; color: white;";
                        }

                        if (newComponent.orientation_errors > 0) {
                            newComponent.orientation_errors_style = "background: red; color: white;";
                        }

                        // check if the component also exist with opposite flipped status, if this is the case mark the flipped component as a possible problem!
                        let j = newProcessedComponentList.findIndex(item =>
                            (item.definition === newComponent.definition) &&
                            (item.is_flipped !== newComponent.is_flipped)
                        );
                        if (j > -1) {
                            newComponent.hide_make_unique = false;
                            newComponent.hide_set_ignore_flipped = false;
                            newProcessedComponentList[j].hide_make_unique = false;
                            newProcessedComponentList[j].hide_set_ignore_flipped = false;

                            if (newComponent.is_flipped) {
                                newComponent.definition_style = 'background: red; color: white;';
                                newComponent.fileNameSuffix = "__FLIPPED";
                            } else {
                                newProcessedComponentList[j].definition_style = 'background: red; color: white;';
                                newProcessedComponentList[j].fileNameSuffix = "__FLIPPED";
                            }
                        }

                        newProcessedComponentList.push(newComponent);
                    }
                });

                newProcessedComponentList.sort(function (a, b) {
                    let idxA = a.definition.lastIndexOf('#');
                    let idxB = b.definition.lastIndexOf('#');
                    if (idxA > -1 && idxB > -1 && a.definition.substring(0, idxA) === b.definition.substring(0, idxB)) {
                        if (parseInt(a.definition.substring(idxA + 1)) < parseInt(b.definition.substring(idxB + 1))) return -1;
                        if (parseInt(a.definition.substring(idxA + 1)) > parseInt(b.definition.substring(idxB + 1))) return 1;
                    } else {
                        if (a.definition < b.definition) return -1;
                        if (a.definition > b.definition) return 1;
                    }
                    if (a.is_flipped < b.is_flipped) return -1;
                    if (a.is_flipped > b.is_flipped) return 1;
                    return 0;
                });

                return newProcessedComponentList;
            }, [this.rawComponentList, this.ignoreFlippedDefinitions, this.enableBendAllowanceDeduction]).then(data => {
                this.processedComponentList = data;
                this.tableReload++;
                this.state.loading = false;
            }).catch(error => {
                console.log("PunchPlates2:getPunchPlates2Callback():error:", error);
            });
        },
        resetIgnoreFlipped(component) {
            this.state.loading = true;
            let idx = this.ignoreFlippedDefinitions.indexOf(component.definition);
            if (idx > -1) {
                this.ignoreFlippedDefinitions.splice(idx, 1);
            }
            this.processComponentList();
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            if ('entity_id' in component) {
                sketchup.selectComponent(component.entity_id);
            } else {
                sketchup.selectComponents(component.entity_ids);
            }
        },
        setIgnoreFlipped(component) {
            this.state.loading = true;
            let idx = this.ignoreFlippedDefinitions.indexOf(component.definition);
            if (idx === -1) {
                this.ignoreFlippedDefinitions.push(component.definition);
            }
            this.processComponentList();
        },
        toggleBendAllowanceDeduction(data) {
            this.state.loading = true;
            this.enableBendAllowanceDeduction = data.toggled;
            sketchup.getPunchPlates2(this.data.filterMaterial, this.enableBendAllowanceDeduction ? this.data.bendAllowanceDeduction : null);
        },
        viewComponent(component) {
            this.viewComponentDialogParameters.title = component.definition;
            this.viewComponentDialogParameters.actions = this.actions.view;
            this.viewComponentDialogParameters.component = component;
            this.showViewComponentDialog = true;
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            if ('entity_id' in component) {
                sketchup.zoomExtents(component.entity_id);
            } else {
                sketchup.zoomExtents(component.entity_ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    },
}
</script>

<style scoped>
</style>