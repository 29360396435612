<template>
    <div>

        <ComponentDialog v-if="showComponentDialog" v-bind="componentDialogParameters"
                         v-on:hidden="showComponentDialog = false"
                         v-on:select-component="selectComponent"
                         v-on:zoom-component="zoomComponent"
                         v-on:zoom-reset="zoomReset"/>

        <ViewComponentDialog v-if="showViewComponentDialog" v-bind="viewComponentDialogParameters"
                             v-on:hidden="showViewComponentDialog = false"
                             v-on:select-component="selectComponent(viewComponentDialogParameters.component)"
                             v-on:zoom-component="zoomComponent(viewComponentDialogParameters.component)"
                             v-on:zoom-reset="zoomReset"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="width: 100%;">
                    <tr>
                        <td style="padding: 0 0 0 1rem; width: 280px;">
                            <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                            {{ $i18n.tc('translations.' + name, 2).toUpperCase() + ' (LP#)' }}
                        </td>
                        <template v-if="serverDataLoaded">
                            <td style="width: 1rem;"></td>
                            <template v-if="data.model.project">
                                <td style="padding: 0 10px; border: solid 1px lightgray; width: 30%;" v-if="data.model.phase">
                                    {{ data.model.phase.label }}
                                </td>
                                <td style="width: 30%;" v-else/>
                                <td style="width: 1rem;"/>
                                <td style="padding: 0 10px; border: solid 1px lightgray;">
                                    <LinkAction :action="actions.modelLinkAction"/>
                                </td>
                                <td style="padding: 0; width: 90px; text-align: right;">
                                    (#{{ data.model.phase ? data.model.phase.id : (data.model.order ? data.model.order.id : data.model.project.id) }})
                                </td>
                            </template>
                            <template v-else>
                                <td style="padding: 0 10px; border: solid 1px lightgray;">
                                    <router-link :to="{name: 'order_list'}" class="st-cardheader-link">
                                        {{ $i18n.t('translations.SketchUp model is not linked to a project') }}!
                                    </router-link>
                                </td>
                            </template>
                            <td style="width: 1rem;"></td>
                        </template>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="serverDataLoaded">

                <div class="card" style="border: none;">
                    <div class="card-header light" style="border: none;">
                        <div class="float-right" style="display: inline-block;">
                            <template v-for="(action,actionIndex) in actions.document">
                                <ButtonAction :key="action.name+'-'+actionIndex"
                                              :action="action" :disabled="state.disabled"
                                              v-on:generate-dxf-files="launchPunchPlates"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </div>
                    </div>
                </div>

                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination pagination-always-visible no-state-loading autofocus :lines-per-page=35
                       :rows="filteredComponentList"
                       :fields="fields.table" :actions="actions.table" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:delete-selection="deleteSelection"
                       v-on:detail-components="detailComponents"
                       v-on:download-event="download"
                       v-on:refresh="getPunchPlates"
                       v-on:renumber-definitions="renumberDefinitions"
                       v-on:select-all="selectAll"
                       v-on:select-color="selectColor"
                       v-on:select-color-location="selectColorLocation"
                       v-on:select-component="selectComponent"
                       v-on:view-component="viewComponent"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>

            </div>

        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import ComponentDialog from "@/components/dialogs/sketchup/ComponentDialog.vue";
import FileDownload from "@/mixins/FileDownload";
import LinkAction from "@/components/actions/LinkAction.vue";
import SketchUp from "@/mixins/SketchUp";
import Table from "@/components/Table";
import ViewComponentDialog from "@/components/dialogs/sketchup/ViewComponentDialog.vue";

/* global sketchup:false */

export default {
    name: 'PunchPlates',
    components: {
        ButtonAction,
        ComponentDialog,
        LinkAction,
        Table,
        ViewComponentDialog,
    },
    props: ['name', 'icon', 'api', 'state'],
    mixins: [FileDownload, SketchUp],
    data() {
        return {
            serverDataLoaded: false,
            tableReload: 0,
            sketchUpToolData: null,
            data: null,
            fields: null,
            actions: null,
            processedComponentList: [],
            filteredComponentList: [],
            filterColor: null,
            filterColorLocations: {
                matchFaceMaterial: true,
                matchFaceBackMaterial: true,
                matchComponentMaterial: true,
            },
            showComponentDialog: false,
            componentDialogParameters: {
                title: null,
                actions: null,
                fields: null,
                rows: null,
            },
            showViewComponentDialog: false,
            viewComponentDialogParameters: {
                title: null,
                actions: null,
                component: null,
            },
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        add_color_filter_choice(choices, color, label) {
            if (color && choices.findIndex(choice => choice.value === color) === -1) {
                choices.push({
                    'icon': ['fas', 'circle'],
                    'iconsize': 'xs',
                    'iconstyle': 'color:' + color,
                    'value': color,
                    'label': label,
                });
            }
        },
        add_component_color(colors, color, label) {
            if (color && colors.filter(c => c.value === color).length === 0) {
                colors.push({
                    value: color,
                    label: label,
                });
            }
        },
        clearSelection() {
            sketchup.clearSelection();
        },
        component_matches_filter_color(component) {
            if (!this.filterColor) {
                return true;
            }
            let match = false;
            component.instances.forEach(instance => {
                if (this.instance_matches_filter_color(instance)) {
                    match = true;
                }
            });
            return match;
        },
        deleteSelection(data) {
            data.ids.forEach(id => {
                let idx = this.processedComponentList.findIndex(component => component.id === id);
                this.processedComponentList.splice(idx, 1);
            });
            this.filter();
        },
        detailComponents(component) {
            let rows = [];
            component.instances.forEach(instance => {
                let instanceIdx = rows.findIndex(row => row.entity_id === instance.entityID);
                if (instanceIdx === -1) {
                    rows.push({
                        id: rows.length + 1,
                        entity_id: instance.entityID,
                        instances: 1,
                        face_color: {
                            value: instance.face_color,
                            label: instance.face_color_name,
                        },
                        face_back_color: {
                            value: instance.face_back_color,
                            label: instance.face_back_color_name,
                        },
                        component_color: {
                            value: instance.component_color,
                            label: instance.component_color_name,
                        },
                    });
                } else {
                    rows[instanceIdx].instances++;
                }
            });
            this.componentDialogParameters.title = component.definition;
            this.componentDialogParameters.actions = this.actions.detail;
            this.componentDialogParameters.fields = this.fields.detail;
            this.componentDialogParameters.rows = rows;
            this.showComponentDialog = true;
        },
        download(choice) {
            this.state.loading = true;
            let components = [];
            this.filteredComponentList.forEach(component => {
                components.push({
                    definition: component.definition,
                    components_in_selection: component.components_in_selection,
                    instances_in_model: component.instances_in_model,
                    instances_in_selection: component.instances_in_selection,
                    vertices: component.vertices,
                    rivets: component.rivets,
                });
            });
            this.$http.post(choice.api, {
                'components': components,
            }, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("PunchPlates:download():error:", error);
            });
        },
        fetchData() {
            this.state.loading = true;
            this.$http.get(this.api + '/manage_punchplates/' + this.sketchUpToolData.project_id + '/' + this.sketchUpToolData.order_id + '/' + this.sketchUpToolData.phase_id, {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.data = res.data.data;
                this.serverDataLoaded = true;
                this.getPunchPlates();
            }).catch((error) => {
                console.log("PunchPlates:fetchData():error:", error);
            });
        },
        filter() {
            this.filteredComponentList = [];
            this.processedComponentList.forEach(component => {
                if (this.component_matches_filter_color(component)) {
                    let instances_with_color = 0
                    if (this.filterColor) {
                        component.instances.forEach(instance => {
                            if (this.instance_matches_filter_color(instance)) {
                                instances_with_color++;
                            }
                        });
                    }
                    component.instances_with_color = instances_with_color;
                    this.filteredComponentList.push(component);
                }
            }, this);
            this.tableReload++;
        },
        getModelDefinitionsCallback(definitions) {
            let definitionsToRenumber = this.getDefinitionsToRenumber(definitions, ['LP']);
            sketchup.renumberDefinitions(definitionsToRenumber);
        },
        getPunchPlates() {
            this.state.loading = true;
            sketchup.getPunchPlates();
        },
        getPunchPlatesCallback(data) {
            let rawComponentList = JSON.parse(data);
            this.processedComponentList = [];

            let colorFilterChoices = [];
            rawComponentList.forEach(component => {
                let newComponent = component;
                newComponent.id = this.processedComponentList.length + 1;
                newComponent.selected = false;

                newComponent.coordinate_system_error = '';
                if (newComponent.axes_error === 1) {
                    newComponent.coordinate_system_error = 'X';
                    newComponent.coordinate_system_error_style = "background: red; color: white;";
                }
                if (newComponent.plate_is_folded === 1) {
                    newComponent.fold_error = 'X';
                    newComponent.fold_error_style = "background: red; color: white;";
                }

                let ids = [];
                let colors = [];
                newComponent.instances.forEach(instance => {
                    ids.push(instance.entityID);
                    this.add_color_filter_choice(colorFilterChoices, instance.face_color, instance.face_color_name);
                    this.add_color_filter_choice(colorFilterChoices, instance.face_back_color, instance.face_back_color_name);
                    this.add_color_filter_choice(colorFilterChoices, instance.component_color, instance.component_color_name);
                    this.add_component_color(colors, instance.face_color, instance.face_color_name);
                    this.add_component_color(colors, instance.face_back_color, instance.face_back_color_name);
                    this.add_component_color(colors, instance.component_color, instance.component_color_name);
                }, this);

                newComponent.material_color_list = colors;

                newComponent.components_in_selection = [...new Set(ids)].length;
                newComponent.instances_in_selection = newComponent.instances.length;

                newComponent.vertices = 0;
                newComponent.rivets = 0;
                newComponent.loops.forEach(loop => {
                    if (loop.outer) {
                        newComponent.vertices = loop.vertices.length;
                    } else if (loop.circle) {
                        newComponent.rivets++;
                    }
                });
                if (newComponent.vertices === 0) {
                    newComponent.vertices_style = "font-weight: bold; color: white; background: red;";
                }

                this.processedComponentList.push(newComponent);
            });

            this.processedComponentList.sort(function (a, b) {
                if (a.definition.substring(0, 3) === 'LP#' && b.definition.substring(0, 3) === 'LP#') {
                    if (parseInt(a.definition.substring(3)) < parseInt(b.definition.substring(3))) return -1;
                    if (parseInt(a.definition.substring(3)) > parseInt(b.definition.substring(3))) return 1;
                } else {
                    if (a.definition < b.definition) return -1;
                    if (a.definition > b.definition) return 1;
                }
                return 0;
            });

            colorFilterChoices.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
            let actionIdx = this.actions.table.findIndex(action => action.name === 'select-color');
            this.actions.table[actionIdx].choices = [{
                'value': null,
                'label': this.$i18n.tc('translations.No Filter on Color', 1),
            }].concat(colorFilterChoices);
            if (colorFilterChoices.filter(choice => choice.value === this.filterColor).length === 0) {
                this.filterColor = null;
            }
            this.actions.table[actionIdx].selected = this.filterColor;

            this.filter();
            this.state.loading = false;
        },
        getToolDataCallback(data) {
            this.sketchUpToolData = data;
            this.fetchData();
        },
        instance_matches_filter_color(instance) {
            return (
                (this.filterColorLocations.matchFaceMaterial && instance.face_color === this.filterColor) ||
                (this.filterColorLocations.matchFaceBackMaterial && instance.face_back_color === this.filterColor) ||
                (this.filterColorLocations.matchComponentMaterial && instance.component_color === this.filterColor)
            );
        },
        launchPunchPlates(data) {
            this.state.loading = true;

            // XML Document
            let xml = document.implementation.createDocument(null, "PunchPlates");
            let elements = xml.getElementsByTagName("PunchPlates");

            // Options element
            let options = xml.createElement("Options");
            options.setAttribute("version", this.data.punchPlateUtilityVersion);
            if ('polygon-offset' in data) {
                options.setAttribute("polygonOffset", data['polygon-offset']);
            } else {
                options.setAttribute("polygonOffset", '3');
            }
            if ('filename-prefix' in data) {
                options.setAttribute("fileNamePrefix", data['filename-prefix']);
            } else {
                options.setAttribute("fileNamePrefix", '');
            }
            if ('label-prefix' in data) {
                options.setAttribute("labelPrefix", data['label-prefix']);
            } else {
                options.setAttribute("labelPrefix", '');
            }
            options.setAttribute("targetDir", this.data.dropBoxLocation);
            elements[0].appendChild(options);

            let noRowsSelected = true;
            this.filteredComponentList.forEach(punchplate => {
                if (punchplate.selected) {
                    noRowsSelected = false;
                }
            });
            this.filteredComponentList.forEach(p => {
                if (noRowsSelected || p.selected) {
                    let punchplate = xml.createElement("PunchPlate");
                    punchplate.setAttribute("definition", p.definition);
                    punchplate.setAttribute("quantity", p.instances_in_selection);
                    let face = xml.createElement("Face");

                    p.loops.forEach(l => {
                        let loop = xml.createElement("Loop");
                        loop.setAttribute("outer", l.outer);
                        loop.setAttribute("circle", l.circle);
                        if (l.circle) {
                            let arccurve = xml.createElement("ArcCurve");
                            arccurve.setAttribute("x", l.arccurve.x);
                            arccurve.setAttribute("y", l.arccurve.y);
                            arccurve.setAttribute("radius", l.arccurve.radius);
                            loop.appendChild(arccurve);
                        } else {
                            l.vertices.forEach(v => {
                                let vertex = xml.createElement("Vertex");
                                vertex.setAttribute("x", v.x);
                                vertex.setAttribute("y", v.y);
                                loop.appendChild(vertex);
                            });
                        }
                        face.appendChild(loop);
                    });

                    punchplate.appendChild(face);
                    elements[0].appendChild(punchplate);
                }
            });

            let sXml = (new XMLSerializer()).serializeToString(xml);
            sketchup.launchPunchPlates(process.env.VUE_APP_TEMP_DIR, process.env.VUE_APP_PUNCHPLATES_DIR, sXml);
        },
        launchPunchPlatesCallback() {
            this.state.loading = false;
        },
        renumberDefinitions() {
            this.state.loading = true;
            sketchup.getModelDefinitions();
        },
        renumberDefinitionsCallback() {
            this.getPunchPlates();
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            if ('entity_id' in component) {
                sketchup.selectComponent(component.entity_id);
            } else {
                let ids = [];
                component.instances.forEach(instance => {
                    if (!this.filterColor || this.instance_matches_filter_color(instance)) {
                        ids.push(instance.entityID);
                    }
                }, this);
                sketchup.selectComponents(ids);
            }
        },
        selectColor(choice) {
            this.filterColor = choice.value;
            this.filter();
        },
        selectColorLocation(choices) {
            choices.forEach(choice => {
                this.filterColorLocations[choice.name] = choice.value;
            });
            this.filter();
        },
        viewComponent(component) {
            this.viewComponentDialogParameters.title = component.definition;
            this.viewComponentDialogParameters.actions = this.actions.view;
            this.viewComponentDialogParameters.component = component;
            this.showViewComponentDialog = true;
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            if ('entity_id' in component) {
                sketchup.zoomExtents(component.entity_id);
            } else {
                let ids = [];
                component.instances.forEach(instance => {
                    if (!this.filterColor || this.instance_matches_filter_color(instance)) {
                        ids.push(instance.entityID);
                    }
                }, this);
                sketchup.zoomExtents(ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>
